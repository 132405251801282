import Api from '@/services/Index';

const getStaffSearch = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/send/sms/staffs/search', config);
}

const sendSmsStaff = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/send/sms/staff', formData);
}

const getStudentSearch = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/send/sms/students/search', config);
}
const sendSmsStudent = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/send/sms/student', formData);
}

const sendSmsPhoneNumber = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/send/sms/phone-number', formData);
}



export default {
    getStaffSearch,
    sendSmsStaff,
    getStudentSearch,
    sendSmsStudent,
    sendSmsPhoneNumber
}
